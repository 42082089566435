.header {
   display: flex;
   flex-direction: row;
}

.nav {
   padding: 1rem 4rem;
   display: flex;
   align-items: center;
   /* width: 100%; */
   justify-content: space-between;
}

.brandContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   /* justify-content: center; */
}

.brand {
   height: 4.5rem;
   width: 4.5rem;
}

.brandSupport {
   font-size: 1.1rem;
   display: inline-block;
   width: 100%;
   margin-left: 2rem;
}

.navigationWrapper {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   gap: 1rem;
}
.homeIconWrapper,
.logoutIconWrapper {
   padding: 7px;
   border-radius: 50%;
   background: #fdb100;
   transition: all 0.5s ease;
}

.logoutIconWrapper {
   background: #ffd8d8;
}
.homeIcon,
.logoutIcon {
   height: 25px;
   width: 25px;
   vertical-align: middle;
   /* margin: 3rem; */
}

.dropdownMenu:hover {
   cursor: pointer;
}

.homeIconWrapper:hover {
   background: #fdb100;
   cursor: pointer;
}
.logoutIconWrapper:hover {
   cursor: pointer;
   background: rgba(217, 84, 84, 1);
}

.chevronIcon {
   margin-left: 0.3rem;
   width: 20px;
}

.horizontalLine {
   background-color: #d4d4d4;
   height: 3px;
   /* width: 100%; */
   margin: 0 3rem;
}

.headerContainer {
   width: 100%;
}

.footer > img {
   width: 15rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
   margin-left: 4rem;
}

.bodySystemWrapper {
   display: flex;
   justify-content: center;
}

.printContent {
   position: absolute;
   bottom: 10vh;
   right: 0;
   background-color: #cfcfcf;
   padding: 10px;
   border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
   /* border: 1px #f5e4e4 solid; */
   cursor: pointer;
}

.row {
   display: flex;
   align-items: stretch;
   /* background-color: red; */
}

.brandSupportPrint {
   display: none;
   font-size: 1.1rem;
   width: 100%;
   margin-left: 2rem;
}

@media only screen and (max-width: 1100px) {
   .row {
      display: flex;
      flex-direction: column;
   }
}

.buttonPrint {
   outline: none;
   box-shadow: none;
   border: none;
   background-color: #cfcfcf;
}

.printSpacer {
   /* background-color: red; */
   width: 100%;
   height: 0.95in;
}

.printSpacerHome {
   height: 1.5in;
}

.otherPages {
   transform: scale(0);
   height: 0;
   /* height: 100%; */
}
@media print {
   @page {
      /* size: 18.4in 14.5in !important; */
      size: 1800px 900px landscape;

      /* size: A3 landscape !important;

   font-size: 3rem;
   */
   }

   .printContent {
      display: none;
   }

   .otherPages {
      transform: scale(1);
      height: 100%;
   }

   .home {
      /* size: 1300px landscape !important; */
      /* margin: auto; */
      width: 1600px !important;
      font-size: 1rem;
   }

   .buttonPrint {
      display: none;
   }

   /* body::before {
      margin: 0;
      padding: 0;
   }
   body {
      margin: 0;
      padding: 0;
      width: 1652px;
      size: 1652px;
      zoom: 80%;
      position: absolute;
      top: 0;
      left: 0;
   }*/
}

/* .bodysystembreakdown {
   page-break-inside: avoid !important;
   width: 48vw !important;
   display: inline-block;
}
.overallhealthscore {
   display: inline-block;
   width: 48vw !important;
} */
