.portal {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.7);
}

.model {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: white;
   padding: 2rem;
   border-radius: 20px;
}

.iconWrapper {
   display: flex;
   justify-content: center;
}

.successIcon {
   width: 5rem;
}

.messageTitle {
   color: rgba(107, 143, 89, 1);
   font-weight: 700;
   font-size: 1.4rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
   text-align: center;
}

.heading {
   font-size: 1.4rem;
   font-weight: 700;
   margin-bottom: 0;
   margin-top: 1rem;
   /* margin-bottom: 1rem; */
   text-align: center;
}

.messageTitleWarnig {
   color: gray;
   font-size: 1.1rem;
   margin: auto;
   padding: 2rem 0;
   width: 80%;
   text-align: center;
}

.messageTitleInfo {
   color: gray;
   font-size: 1rem;
   margin: 1rem;
   padding: 1rem 0;
   text-align: left;
}

.button {
   font-family: inherit;
   font-size: 1.1rem;
   font-weight: 600;
}

.messageTitleError {
   color: #d95454;
   font-weight: 700;
   font-size: 1.4rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
   text-align: center;
}
