.header {
   display: flex;
   flex-direction: row;
   /* width: 100vw; */
}
.haderContainer {
   width: 100vw;
}

.nav {
   margin: 1rem 4rem;
   display: flex;
   align-items: center;
   background-color: transparent;
   width: 94vw;
   flex-direction: row;
   justify-content: space-between;
}

.brandContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   /* justify-content: center; */
}

.adminIconWrapper {
   padding: 7px;
   border-radius: 50%;
   background: #fdb100;
   transition: all 0.5s ease;
   margin-right: 1rem;
}

.adminIconWrapper {
   background: rgb(197, 197, 197);
}

.adminIconWrapper:hover {
   background: gray;
}

.brand {
   height: 4.5rem;
   width: 4.5rem;
}

.brandSupport {
   font-size: 1rem;
   display: inline-block;
   width: 100%;
   margin-left: 2rem;
   border: #6b8f59 solid 2px;
   padding: 0.8rem 2rem;
   font-weight: 600;
   border-radius: 10px;
   color: #6b8f59;
}

.info {
   height: 30px;
   width: 30px;
   /* padding: 2rem; */
}

.wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
}

.infoButton {
   padding: 0.4rem;
   /* background-color: red; */
   /* display: inline-block; */
   /* height: 30px;
  width: 30px; */

   border: none;

   background-color: transparent;
   outline: none;
   box-shadow: none;
}

.infoButton:hover {
   cursor: pointer;
}

/* .inputFieldFile {
  height: 300px;
  width: 500px;
} */

.horizontalLine {
   background-color: #d4d4d4;
   height: 3px;
   /* width: 100%; */
   margin: 0 3rem;
}

.uploadSection {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.dropZone {
   height: 300px;
   width: 50%;
   margin: auto;
   margin-top: 10vh;
   background-color: #6b8f59;
   display: flex;
   align-items: center;
   border-radius: 20px;
   justify-content: center;
   font-size: 1.2rem;
   font-family: inherit;
   text-transform: uppercase;
   /* font-weight: 600; */
   letter-spacing: 2px;
   color: white;
}

.dropZone:hover {
   cursor: pointer;
}

.filename {
   text-align: center;
   margin: 3rem 0;
}

.buttonUpload {
   outline: none;
   border: none;
   padding: 1rem 2.5rem;
   font-size: 1rem;
   color: white;
   background-color: goldenrod;
   border-radius: 20px;
   transition: 0.5s all ease;
}

.buttonUpload:hover {
   cursor: pointer;
   box-shadow: 0px 0px 7px goldenrod;
}

.buttonDisabled {
   outline: none;
   border: none;
   padding: 1rem 2.5rem;
   font-size: 1rem;
   color: white;
   background-color: rgb(185, 185, 185);
   border-radius: 20px;
   transition: 0.5s all ease;
}
