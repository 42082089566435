.systemCard {
   display: flex;
   width: 48%;
   margin-top: 0.2rem;
}

.detailsArea {
   /* display: flex; */
   /* flex-direction: column; */
   margin-left: 0.5rem;
}

.detailsArea > h3 {
   font-size: 1rem;
   font-weight: 700;
   /* line-height: 0.5rem; */
}

.detailsArea > p {
   font-size: 0.8rem;
}

.detailsArea > p:nth-child(2) {
   font-weight: 600;
   margin: 0.1rem;
}

.link {
   text-decoration: none;
   color: black;
}

.link:hover {
   text-decoration: none;
   color: black;
}
