@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

* {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "Nunito Sans", sans-serif;
   box-sizing: content-box;
}

.container {
   /* margin-left: 4rem !important; */
   margin: auto 4rem !important;
   /* margin-left: 4rem !important;
   margin-right: 4rem !important; */
   /* margin-x: auto !important; */
   /* width: calc(100vw - 1rem) !important; */
   max-width: calc(100vw - 8rem) !important;
}
