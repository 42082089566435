.header {
   display: flex;
   flex-direction: row;
}
.headerContainer {
   width: 100vw;
}

.nav {
   padding: 1rem 4rem;
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: space-between;
}

.brandContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   /* justify-content: center; */
}

.brand {
   height: 4.5rem;
   width: 4.5rem;
}

.brandSupport {
   font-size: 1.1rem;
   display: inline-block;
   width: 100%;
   margin-left: 2rem;
}

.navigationWrapper {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   gap: 1rem;
}
.homeIconWrapper,
.logoutIconWrapper,
.adminIconWrapper,
.uploadIconWrapper {
   padding: 7px;
   border-radius: 50%;
   background: #fdb100;
   transition: all 0.5s ease;
}

.logoutIconWrapper {
   background: #ffd8d8;
}

.adminIconWrapper {
   background: rgb(179, 179, 179);
}

.uploadIconWrapper {
   background: rgb(197, 197, 197);
}

.uploadIconWrapper:hover {
   background-color: rgba(107, 143, 89, 1);
}

.homeIcon,
.logoutIcon,
.adminIcon {
   height: 25px;
   width: 25px;
   vertical-align: middle;
}

.homeIconWrapper:hover {
   background: #fdb100;
   cursor: pointer;
}
.logoutIconWrapper:hover {
   cursor: pointer;
   background: rgba(217, 84, 84, 1);
}

.adminIconWrapper:hover {
   cursor: pointer;
   background: gray;
}

.horizontalLine {
   background-color: #d4d4d4;
   height: 3px;
   margin: 0 3rem;
}

.headerSelection {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

.selection {
   display: flex;
   flex-direction: row;
   align-items: center;
}
.headerSelectionText {
   font-size: 1rem;
   margin: 0;
   margin-right: 1rem;
}

.deleteWrapper {
   /* background-color: rgba(240, 180, 41, 1); */
   padding: 2rem;
   width: 80%;
   border-radius: 10px;
   margin: auto;
   margin-top: 10vh;
   height: 600px;
   border: #fdb100 solid 2px;
}

.dropdownMenu {
   background-color: aliceblue;
   outline: none;
   border: none;
   padding: 1rem;
   border-right: 16px solid transparent;
}

.textFields {
   display: flex;
   flex-direction: row;
}

.textFields > div > input {
   width: 200px;
   margin-left: 1rem;
   margin-right: 2rem;
   padding: 0.5rem 1rem;
   font-family: inherit;
   background-color: aliceblue;
   border: none;
   outline: none;
}

.searchButton {
   background-color: #fac342;
   border: none;
   border-radius: 10px;
   padding: 0.5rem 1rem;
   color: white;
   font-size: 1rem;
   cursor: pointer;
   outline: none;
}

.searchButton:hover {
   background-color: #f8ae00;
}

.detailsArea {
   background-color: aliceblue;
   margin: 2rem 0;
   border-radius: 10px;
   padding: 2rem;
}

.userDetails > h3 {
   font-size: 1.1rem;
   margin: 0;
}

.userDetailsWrapper {
   margin: 2rem 1rem;
   display: flex;
   justify-content: space-between;
}

.userDetailsTextGroup {
   display: flex;
   width: 30%;
   flex-direction: row;
   /* background-color: red; */
}

.userDetailsText {
   margin-right: 1rem;
}

.reportDetails {
   font-size: 1.1rem;
   margin: 0;
}

.buttonWrapper {
   display: flex;
   justify-content: center;
}

.deleteButton {
   background-color: red;
   outline: none;
   border: none;
   padding: 0.5rem 1rem;
   border-radius: 10px;
   color: white;
   width: 100px;
}

/* // rgba(107, 143, 89, 1) - green
// rgba(240, 180, 41, 1) - yellow
// rgba(217, 84, 84, 1) - red */
