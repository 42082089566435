.header {
   display: flex;
   flex-direction: row;
}

.nav {
   padding: 1rem 4rem;
   display: flex;
   align-items: center;
   /* width: 100%; */
   justify-content: space-between;
}

.brandContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   /* justify-content: center; */
}

.brand {
   height: 4.5rem;
   width: 4.5rem;
}

.brandSupport {
   font-size: 1.1rem;
   display: inline-block;
   width: 100%;
   margin-left: 2rem;
}

.navigationWrapper {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   gap: 1rem;
}
.homeIconWrapper,
.logoutIconWrapper {
   padding: 7px;
   border-radius: 50%;
   background: #cfcfcf;
   transition: all 0.2s ease-in;
}

.logoutIconWrapper {
   background: #ffd8d8;
}
.homeIcon,
.logoutIcon {
   height: 25px;
   width: 25px;
   vertical-align: middle;
   /* margin: 3rem; */
}

.homeIconWrapper:hover {
   background: #fdb100;
   cursor: pointer;
}
.logoutIconWrapper:hover {
   cursor: pointer;
   background: rgba(217, 84, 84, 1);
}

.horizontalLine {
   background-color: #d4d4d4;
   height: 3px;
   /* width: 100%; */
   margin: 0 3rem;
}

.headerContainer {
   width: 100%;
}

.analysisName {
   font-size: 1rem;
   /* display: inline-block; */
   margin-left: 2rem;
   font-weight: 600;
   margin-top: 0.4rem;
}

.horizontalLine {
   background-color: #d4d4d4;
   height: 2px;
   margin: 0 3rem;
   border-radius: 10px;
}

.footer > img {
   width: 15rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
   margin-left: 4rem;
}

.systemWrapper {
   /* background-color: blue; */
   height: 5rem;
   /* width: 20%; */
   padding: 0 1rem;
   /* margin: 1rem 4rem; */
   border-radius: 20px;
   border: 0.25px solid black;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
}

.systemWrapper > p {
   font-size: 1.1rem;
   font-weight: 700;
   margin: 0 1rem;
}

.chartWrapper {
   height: 100%;
   /* width: 100%; */
   /* background-color: red; */
   margin-left: 4rem;
   margin-right: 4rem;
   margin-top: 2rem;
   margin-bottom: 2rem;
}

.barChartWrapper {
   display: flex;
   flex-direction: row;
   gap: 10px;
   flex-wrap: wrap;
   /* min-height: 100vh; */
   /* position: relative; */
}

.chartWrapper {
   position: relative;
   min-height: 80vh;
}

.grayTree {
   position: absolute;
   height: 40rem;
   width: 40rem;
   scale: 1.1;
   opacity: 0.5;
   top: 0;
   right: 10%;
   z-index: -1;
}

.printContent {
   position: absolute;
   bottom: 10vh;
   right: 0;
   background-color: #cfcfcf;
   padding: 10px;
   border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
   /* border: 1px #f5e4e4 solid; */
   cursor: pointer;
}

.buttonPrint {
   outline: none;
   box-shadow: none;
   border: none;
   background-color: #cfcfcf;
}

@media print {
   .printContent {
      display: none;
   }
}
