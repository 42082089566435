.footer {
   display: flex;
   margin: 1rem 4rem;
   justify-content: space-between;
   /* background-color: blue; */
   align-items: center;
}

.footer > img {
   width: 15rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
}

.navigationPanel {
   width: 100px;
   display: flex;
   justify-content: space-between;
}

.arrowIcons {
   width: 40px;
   color: red;
   transition: all 0.3s ease;
   border-radius: 50%;
}

.arrowIcons:hover {
   cursor: pointer;
   background-color: rgba(240, 180, 41, 1);
   border-radius: 50%;
}

.disable {
   width: 40px;
   transition: all 0.3s ease;
   border-radius: 50%;
}

.disable:hover {
   cursor: pointer;
   background-color: gray !important;
   border-radius: 50%;
}

@media print {
   .footer {
      display: none;
   }
}
