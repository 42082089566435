.barChart {
   /* width: 10rem; */
   border: 0.25px solid black;
   border-radius: 20px;
   padding: 1rem 0.5rem;
   font-size: 1rem !important;
}

.barChart > p {
   font-size: 0.8rem;
   margin-left: 0.5rem;
}

canvas {
   height: 12rem !important;
   width: 12rem !important;
}
