/* .container {
   justify-content: center;
} */

.cardBodySystemBreakDown {
   margin-bottom: 0 2rem;
   display: flex;
   flex-direction: column;
   width: 100%;
   /* flex-grow: 1; */
   /* width: 90%; */
   border-radius: 20px;
   /* border: 0.25px solid black; */
   position: relative;
   margin: 1rem 0;
   margin-right: 3.5rem;
}

.backgroundImage {
   background-image: url("./../../assets/images/Muscle\ man.png");
   background-position: center;
   background-size: contain;
   height: 70%;
   width: 40%;
   background-repeat: no-repeat;
   position: absolute;
   opacity: 0.5;
   top: 18%;
   left: 30%;
   margin: auto;
   z-index: -5;
}

.systemCardWrapperMain {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   margin-right: 2rem;
   padding-bottom: 1rem;
}

.cardBodySystemBreakDown {
   padding-left: 2rem;
   padding-top: 2rem;
}
.cardBodySystemBreakDown > h1 {
   font-size: 1.1rem;
   font-weight: 600;
   margin-bottom: 1.5rem;
}

.systemCardsWrapper {
   display: flex;
   justify-content: space-between;
   margin-right: 2rem;
   /* align-items: flex-end; */
   width: 100%;
   /* margin-top: 2rem; */
}
