:root {
   --primaryGreen: #6b8f59;
   --primaryRed: #d95454;
   --primaryYellow: #f0b429;
   --textGraySecondary: #a7a8a8;
   --textGrayPrimary: #6d6d6d;
   --primaryBlack: #000;
}

.overallHealthScoreWrap {
   /* display: inline-block; */
   margin-top: 1rem;
   /* height: 100%; */
   margin-right: 1rem;
   margin-left: 3.5rem;
   width: 100%;
   display: flex;
   flex-direction: column;
}

.cardWrapper {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;

   /* width: 90%; */
   /* flex: 1; */
   /* height: 220px; */
   /* margin: auto; */
}

.cardWrapper:last-child {
   margin-right: 1rem;
}

.horizontalLine {
   color: var(--primaryBlack);
}

.horizontalLineBottom {
   color: var(--primaryBlack);
   margin-bottom: 2rem;
}

.heading {
   font-size: 1.1rem;
   font-weight: 600;
   margin: 1rem 0;
   margin-left: 2rem;
   background-color: red;
}

.smallCardHeading,
.smallCardHeadingDark {
   font-weight: bold;
   font-size: 1rem;
   margin-top: 1rem;
   /* color: #a7a8a8; */
}

.cardHealthRating {
   font-size: 4.5rem;
   font-weight: 900;
   color: #fac071;
   margin: 0.5rem 0;
}

.cardLabName {
   /* font-size: 0.7rem; */
   visibility: hidden;
   display: none;
   margin-top: 1rem;
   margin-bottom: 0.5rem;
}

.mainCardHeading {
   font-size: 1.2rem;
   font-weight: 700;
   margin-bottom: 2rem;
   margin-top: 1rem;
}

.gaugeChart {
   font-weight: 900 !important;
   font-size: 4rem !important;
   margin: 0;

   padding: 0;
}

.gaugeWrapper {
   max-width: 300px !important;
   margin: 0 auto;
   min-width: 150px;
}

.OverallHealthScore_gaugeChart__bKhII {
   padding: 0 !important;
   margin: 0 !important;
}

.gaugeChartWrapper {
   position: relative;
}

.gaugeChartWrapper > span {
   font-weight: 900;
   /* color: #f0b429; */
   font-size: 2.3rem;
   position: absolute;
   top: 60%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.cardWrapperBottom {
   display: flex;

   /* flex-shrink: 0; */
   height: 100%;
   /* background-color: yellow; */
   /* justify-content: space-evenly; */
   flex-direction: row;
   /* height: 100%; */
   margin-top: 1rem;
   width: 100%;
   /* height: 100%; */
   margin-bottom: 1rem;
}

.cardWrapperBottom:last-child {
   margin-right: 1rem;
}

.cardBottomHeading {
   font-family: inherit;
   margin-bottom: 1rem;
   font-size: 1.1rem;
   margin-top: 1rem;
   font-weight: 600;
}
.healthSummaryIconContainer {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 1.5rem;
   margin-bottom: 1.5rem;
}

.healthSummaryIconContainer > p {
   display: inline-block;
   font-size: 2rem;
   font-weight: 700;
   margin-bottom: 0;
}

.healthSummaryBiomarkers {
   font-size: 1rem;
   font-weight: 700;
   margin-top: 0.5rem;
   font-family: inherit;
}

.healthSummarySideNote {
   font-size: 0.9rem;
}

/**
Risk of III health styles
**/

.riskWrapper {
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
}
.riskContainerWrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   width: 50%;
}

.riskCardContainer {
   /* display: inline-block; */
   display: flex;
   /* flex-direction: column; */
   justify-content: center;
   align-items: stretch;
   margin: 1.1rem 0;
}

.riskCardContainer > div {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.riskRangeContainer {
   display: flex;
   flex-direction: column;
   /* width: 60%; */
}
.riskRangeContainer > p {
   display: inline-block;
   text-align: left;
   margin-left: 1rem;
}
.circleGreen {
   border-radius: 50%;
   background-color: var(--primaryGreen);
   height: 3rem;
   width: 3rem;
   /* margin-right: 1rem; */
}

.circleYellow {
   border-radius: 50%;
   background-color: var(--primaryYellow);
   height: 3rem;
   width: 3rem;
}

.circleRed {
   border-radius: 50%;
   background-color: var(--primaryRed);
   height: 3rem;
   width: 3rem;
}

.riskIndicator {
   font-size: 0.9rem;
   font-weight: bold;
}

.riskRange {
   font-size: 0.9rem;
   font-weight: 500;
}

/**
Card Component css style
**/
.card {
   border-radius: 20px;
   /* border: 0.25px solid var(--primaryBlack); */
   padding: 0.5rem;
   /* display: inline-block; */
   text-align: center;
   flex-grow: 1;
   width: 15%;
   width: 100%;
   /* height: 100%; */
   margin-right: 1rem;
   display: flex;
   flex-direction: column;
   /* align-items: center; */
   justify-content: space-between;
   /* max-width: 20%; */
}

.cardContent {
   /* display: inline-block; */
}
.cardGauge {
   border-radius: 20px;
   /* border: 0.25px solid var(--primaryBlack); */
   padding: 0.5rem;
   display: inline-block;
   text-align: center;
   flex-grow: 1;
   width: 100%;
}
.radialContainerWrapper {
   display: inline-block;
   width: calc(50% - 1rem);
}

.cardGaugeFull {
   border-radius: 20px;
   /* border: 0.25px solid var(--primaryBlack); */
   padding: 0.5rem;
   display: inline-block;
   text-align: center;
   /* width: 100%; */
   flex-grow: 1;
   width: 100%;
   /* margin-left: 2rem; */
   /* margin-left: 2rem; */
   /* display: flex; */
}

.topScoreWrapper {
   display: flex;
   align-items: flex-end;
   justify-content: space-around;
}

.horizontalLineBottomTop {
   margin-bottom: 1rem;
   margin-top: 1.5rem;
   width: 100%;
   height: 1px;
   background-color: black;
}

.topChart {
   width: 50%;
   display: flex;

   flex-direction: column;
}

.topChartLeft {
   width: 50%;
   margin-left: 2rem;
   /* background-color: red; */
}

.ScoreTopChart {
   font-size: 5rem;
   font-weight: 800;
}

.mainCardHeadingTop {
   margin-top: 1rem;
}

.healthSummary {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
}

.cardBottom {
   border-radius: 20px;
   /* border: 0.25px solid var(--primaryBlack); */
   padding: 0.5rem;
   display: inline-block;
   text-align: center;
   max-width: 50%;
   flex-grow: 1;
}

.cardBottom:not(:last-child) {
   margin-right: 1rem;
}

.cardWrapContainer {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
}

.centerContainer {
   width: 100%;
   /* display: flex; */
   /* justify-content: stretch; */
}

.previousReportContainer {
   width: 50%;
   margin-right: 1rem;
   display: flex;
   flex-direction: row;
}

.previousReportContainer > .card:last-child {
   margin-right: 0;
}

/**
Utility functions
**/
.red {
   color: #d95454;
}

.yellow {
   color: #fac071;
}

.spacer {
   margin-top: 1rem;
}

@media only screen and (max-width: 1100px) {
   .overallHealthScoreWrap {
      padding: 0 3rem;
      width: 100%;
   }
}
