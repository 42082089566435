.dropdownWrapper {
   background-color: #cfcfcf;
   border-radius: 20px;
}

.dropdownMenu {
   background-color: #cfcfcf;
   margin: 0.5rem 0.4rem;
   border-radius: 20px;
   outline: none;
   border: none;
}
