.doughnutChart {
   height: 6rem;
   width: 6rem;
   /* background-color: red; */
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
}

.doughnutChart > p {
   position: absolute;
   top: 3rem;
   left: 3rem;
   transform: translate(-50%, -50%);
   font-size: 1.2rem;
   font-weight: 700;
}

.doughnutChartClass {
   height: 4rem !important;
   width: 4rem !important;
   /* vertical-align: middle !important; */
}
