.backgroundWrapper {
   background: #6b8f59;
   background-blend-mode: multiply;
   background-repeat: no-repeat;
   background-size: 100vw 100vh;
   height: 100vh;
   width: 100vw;
   overflow: hidden;
}

.container {
   background-color: white;
   margin: auto;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   padding: 2rem 3rem;
   border-radius: 20px;
   overflow: hidden;
}

.brandImageWrapper {
   display: flex;
   justify-content: center;
   align-items: center;
}

.brandImage {
   height: 14rem;
}

.loginHeader {
   font-weight: 600;
   font-family: inherit;
   font-size: 1rem;
   text-align: center;
   margin-top: 1rem;
   color: rgb(108, 133, 96);
   margin-bottom: 1rem;
}

.loginFormWrapper {
   /* background-color: red; */
}

.inputField {
   display: block;
   width: 85%;
   margin: auto;
   background-color: #cfcfcf;
   border: none;
   padding: 0.8rem 1rem;
   border-radius: 10px;
   margin-bottom: 1rem;
   font-family: inherit;
   font-size: 0.8rem;
   letter-spacing: 1px;
   border: 2px #cfcfcf solid;
   transition: all 0.2s ease;
   /* font-weight: 600; */
}

.inputField:hover {
   border: 2px #a19f9f solid;
}
.inputField:focus {
   border: 2px rgb(108, 133, 96) solid;
}

.inputField:active {
   outline: none;
}
.inputField:focus {
   outline: none;
}

.showPassword {
   font-size: 0.8rem;
   color: #9b9999;
   margin-bottom: 1rem;
}

.showPassword:hover {
   cursor: pointer;
}

.checkBox {
   margin-right: 1rem;
}
.submitButton {
   font-size: 0.8rem;
   text-align: center;
   /* margin-top: 1rem; */
   width: 85%;
   padding: 0.8rem 1rem;
   outline: none;
   border: none;
   border-radius: 10px;
   font-weight: 600;
   letter-spacing: 4px;
   color: white;
   background-color: rgba(107, 143, 89, 1);
   margin-bottom: 1.4rem;
}

.errorText {
   color: rgba(217, 84, 84, 1);
   font-size: 0.8rem;
   /* display: none; */
}

.displayNone {
   display: none;
}

/* 
.row {
   display: flex;
   flex-direction: row;
}

.empty {
   width: 49%;
   height: 100vh;
}

.verticalLine {
   background-color: #fff;
   width: 0.3%;
   height: 100vh;
   max-height: 80vh;
   margin: auto;
}

.card {
   display: flex;
   width: 49%;
   height: 100vh;
   align-items: center;
   justify-content: center;
}

.cardBody {
   background-color: #fff;
   display: flex;
   flex-direction: column;
   width: 40%;
   border-radius: 10px;
   animation: cardbox 2s ease-in forwards;
}

@keyframes cardbox {
   from {
      opacity: 0.3;
   }
   to {
      opacity: 1;
   }
}

.cardBody:hover {
}

.imageBox {
   width: 100%;
   height: 100px;
   margin-top: 10px;
   background-image: url(../../assets/images/Brand\ logo.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: 100px 100px;
}

.formBox {
   margin-top: 10px;
   margin-bottom: 20px;
   width: 100%;
}

.formGroupNote {
   display: flex;
   flex-direction: column;
   width: 100%;
   overflow: hidden;
}

input[type="email"],
input[type="password"],
button[type="submit"] {
   background-color: lightgray;
   font-size: 10px;
   line-height: 1em;
   width: 80%;
   padding: 3px;
   margin: 10px;
   margin-top: 5px;
   box-sizing: border-box;
   overflow: hidden;
   border-radius: 5px;
   border: none;
   transition: all 0.5s;
}

button[type="submit"] {
   background-color: green;
   padding: 6px;
   font-weight: bold;
}

input[type="email"]:focus {
   outline: none;
   padding: 5px;
   background-color: #fff;
   box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

input[type="password"]:focus {
   outline: none;
   padding: 5px;
   background-color: #fff;
   box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

button[type="submit"]:target {
   border: none;
} */
.contact-form label,
#recaptcha {
   display: block;
   margin: 0px 0px 15px 0px;
}
.contact-form label > span,
#recaptcha::before {
   width: 100px;
   text-align: right;
   float: left;
   padding-top: 8px;
   padding-right: 20px;
   content: "";
}
.contact-form .textarea-field,
.g-recaptcha {
   width: 70%;
   height: 250px;
   font-size: 18px;
   display: inline-block;
}
