.header {
   display: flex;
   flex-direction: row;
}

.nav {
   padding: 1rem 4rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.brandContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   /* justify-content: center; */
}

.brand {
   height: 4.5rem;
   width: 4.5rem;
}

.brandSupport {
   font-size: 1.1rem;
   display: inline-block;
   width: 100%;
   margin-left: 2rem;
}

.navigationWrapper {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   gap: 1rem;
}
.homeIconWrapper,
.logoutIconWrapper,
.deleteIconWrapper,
.uploadIconWrapper {
   padding: 7px;
   border-radius: 50%;
   background: #fdb100;
   transition: all 0.5s ease;
}

.logoutIconWrapper {
   background: #ffd8d8;
}

.deleteIconWrapper {
   background-color: rgb(197, 197, 197);
}

.uploadIconWrapper {
   background: rgb(197, 197, 197);
}

.homeIcon,
.logoutIcon,
.adminIcon {
   height: 25px;
   width: 25px;
   vertical-align: middle;
}

.homeIconWrapper:hover {
   background: #fdb100;
   cursor: pointer;
}
.logoutIconWrapper:hover {
   cursor: pointer;
   background: rgba(217, 84, 84, 1);
}

.deleteIconWrapper:hover {
   cursor: pointer;
   background: gray;
}

.uploadIconWrapper:hover {
   background-color: rgba(107, 143, 89, 1);
}

.horizontalLine {
   background-color: #d4d4d4;
   height: 3px;
   margin: 0 3rem;
}

.headerContainer {
   width: 100%;
}

.card {
   /* width: 100%; */
   /* height: 100%; */
   padding: 2rem;
   border-radius: 10px;
   box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
   margin-top: 1rem;
}
.cardHeader {
   font-size: 1.1rem;
   text-transform: uppercase;
   font-weight: 600;
}
